import styled from "styled-components";

export const ApplicationScreenshot = styled.img`
  width: 990px;
  border-radius: 12px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1280px) {
    width: calc(100vw - 10%);
    margin: 0 auto;
  }
`;
