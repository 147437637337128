import styled from "styled-components";

export const ShortBox = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 6px;
  color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  margin-right: 8px;
  font-weight: bold;
  font-size: 0.9rem;
`;
