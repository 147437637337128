export const ru = {
  header: {
    navbar: {
      blog: "Блог",
      community: "Сообщество",
      support: "Поддержать",
      download: "Скачать",
    },
    account: "Аккаунт",
  },
  footer: {
    terms: "Условия использования",
    privacy: "Политика конфиденциальности",
    contact: "Контакт",
    faq: "FAQ",
  },
  home: {
    download: "Скачать",
    docs: "Документация",
    description: `Современный крос-платформенный редактор <span>.ass, .ssa</span> субтитров 
    с облачнай синхранизацияй и библиотекой субтитров!`,
  },
  download: {
    download_for: "Скачать для",
    description: "Создавайте субтитры на <span>любой</span> платформе.",
  },
};
