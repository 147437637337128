export const en = {
  header: {
    navbar: {
      blog: "Blog",
      community: "Community",
      support: "Support",
      download: "Download",
    },
    account: "Account",
  },
  footer: {
    terms: "Terms of Use",
    privacy: "Privacy Policy",
    contact: "Contact",
    faq: "FAQ",
  },
  home: {
    download: "Download",
    docs: "Documentation",
    description: `Advanced cross-platform editor for <span>.ass, .ssa</span> subtitles,
    with cloud sync and community library!`,
  },
  download: {
    download_for: "Download for",
    description: "Create subtitles on <span>any</span> platform.",
  },
};
