export const be = {
  header: {
    navbar: {
      blog: "Блог",
      community: "Супольнасць",
      support: "Падтрымаць",
      download: "Спампаваць",
    },
    account: "Улік. запіс",
  },
  footer: {
    terms: "Умовы выкарыстання",
    privacy: "Палітыка прыватнасці",
    contact: "Кантакты",
    faq: "FAQ",
  },
  home: {
    download: "Спампаваць",
    docs: "Дакументацыя",
    description: `Сучасны рэдактар <span>.ass, .ssa</span> субцітраў пад усе платформы,
    з воблачным cінхразаваннем і бібліятэкай бясплатных субцітраў!`,
  },
  download: {
    download_for: "Спампаваць для",
    description: "Стварайце субцітры на <span>любой</span> платформе.",
  },
};
