import styled from "styled-components";

export const FooterLinks = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (max-width: 680px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
