import styled from "styled-components";

export const HeaderTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 12px;
  position: relative;
  color: ${(props) => props.theme.colors.text};
  > span {
    font-weight: 400;
    font-size: 0.8rem;
    position: absolute;
    right: -10px;
    top: -5px;
    color: #ff4a4a;
  }
`;
