import styled from "styled-components";
import { selectedNavLink } from "./NavLink";

export const LanguageButton = styled.button`
  border: none;
  cursor: pointer;

  color: ${(props) => props.theme.colors.text};
  text-decoration: none;
  border-radius: 50px;
  height: 40px;
  padding: 2px 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: inherit;

  background-color: ${(props) => props.theme.colors.white};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary_light};
    > svg {
      color: #2196f3;
    }
  }

  ${({ selected }) => selected && selectedNavLink}
`;
