import { COLORS } from "./colors";

export const lightTheme = {
  colors: {
    primary: COLORS.PRIMARY,
    primary_light: COLORS.PRIMARY_LIGHT,
    primary_dark: COLORS.PRIMARY_DARK,
    background: COLORS.BACKGROUND,
    white: COLORS.WHITE,
    text: COLORS.BLACK,
    text_secondary: COLORS.BLACK,
  },
};
