import styled from "styled-components";
import { Container } from "./Container";

export const HeaderContainer = styled(Container)`
  height: 60px;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  padding-left: 26px;
`;
