import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "./Container";
import { Copyright } from "./footer/Copyright";
import { FooterLink } from "./footer/FooterLink";
import { FooterLinks } from "./footer/FooterLinks";
import { SimpleLink } from "./footer/SimpleLink";
import { Wrapper } from "./footer/Wrapper";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container>
        <FooterLinks>
          <FooterLink to="/terms">{t("footer:terms")}</FooterLink>
          <FooterLink to="/privacy">{t("footer:privacy")}</FooterLink>
          <FooterLink to="/">{t("footer:contact")}</FooterLink>
          <FooterLink to="/">{t("footer:faq")}</FooterLink>
        </FooterLinks>
        <Copyright>
          © {new Date().getFullYear()} VivySub
          <br /> Created by{" "}
          <SimpleLink href="https://vivy.company">Vivy.Company</SimpleLink> with
          ❤️
        </Copyright>
      </Container>
    </Wrapper>
  );
};

export default Footer;
