import { COLORS } from "./colors";

export const darkTheme = {
  colors: {
    primary: COLORS.PRIMARY,
    primary_light: COLORS.PRIMARY_LIGHT,
    primary_dark: COLORS.PRIMARY_DARK,
    background: COLORS.BACKGROUND_DARK,
    white: COLORS.WHITE_DARK,
    text: COLORS.WHITE,
    text_secondary: COLORS.PRIMARY_DARK,
  },
};
