import React, { useCallback, useMemo, useRef } from "react";
import {
  MdArrowBack,
  MdDeveloperBoard,
  MdOutlineCoffee,
  MdOutlineDarkMode,
  MdOutlineGroups,
  MdOutlineLanguage,
  MdOutlineLightMode,
  MdOutlineMenu,
  MdPersonOutline,
} from "react-icons/md";
import Drawer from "react-modern-drawer";
import { useState } from "react";
import { useThemeContext } from "../../core/theme";
import { ControlledMenu } from "@szhsin/react-menu";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./header/Wrapper";
import { HeaderContainer } from "./header/HeaderContainer";
import { Link } from "./header/Link";
import { Box } from "./Box";
import { Logo } from "./header/Logo";
import { HeaderTitle } from "./header/HeaderTitle";

import { Navigation } from "./header/Navigation";
import { NavLink } from "./header/NavLink";
import { LanguageButton } from "./header/LanguageButton";
import { MenuBox } from "./header/MenuBox";
import { HEADER_LANGUAGES } from "../../core/config/langauges";
import { StyledMenuItem } from "./header/StyledMenuItem";
import { ShortBox } from "./header/ShortBox";

import { Burger } from "./header/Burger";
import { DrawerBox } from "./header/DrawerBox";
import { MobileNavigation } from "./header/MobileNavigation";
import { ThemeButton } from "./header/ThemeButton";
import { MobileUserButton } from "./header/MobileUserButton";
import { MobileNavLink } from "./header/MobileNavLink";
import { AccountButton } from "./header/AccountButton";
import { MobileBox } from "./header/MobileBox";
import { DesktopBox } from "./header/DesktopBox";

const Header = () => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();
  const ref = useRef(null);
  const [isOpenLanguage, setIsOpenLanguage] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const theme = useThemeContext();

  const toggleTheme = useCallback(() => {
    theme.changeTheme(!theme.dark);
  }, [theme]);

  const ThemeIcon = useMemo(() => {
    if (theme.dark) {
      return MdOutlineLightMode;
    }

    return MdOutlineDarkMode;
  }, [theme.dark]);

  return (
    <Wrapper>
      <HeaderContainer>
        <Link to="/">
          <Box>
            <Logo src={"/assets/images/logo.png"} />
            <HeaderTitle>
              VivySub<span>β</span>
            </HeaderTitle>
          </Box>
        </Link>
        <DesktopBox>
          <Navigation>
            <NavLink href="https://t.me/s/vivy_page" target="_blank">
              <MdDeveloperBoard size={24} style={{ marginRight: 8 }} />
              {t("header:navbar.blog")}
            </NavLink>
            <NavLink href="https://community.vivysub.app">
              <MdOutlineGroups size={24} style={{ marginRight: 8 }} />
              {t("header:navbar.community")}
            </NavLink>
            <NavLink href="https://boosty.to/vivy" target="_blank">
              <MdOutlineCoffee size={24} style={{ marginRight: 8 }} />
              {t("header:navbar.support")}
            </NavLink>
          </Navigation>
        </DesktopBox>
        <Box>
          <LanguageButton
            ref={ref}
            onClick={() => setIsOpenLanguage(!isOpenLanguage)}
            selected={isOpenLanguage}
          >
            {language.toLocaleUpperCase()}
            <MdOutlineLanguage size={24} style={{ marginLeft: 8 }} />
          </LanguageButton>
          <ControlledMenu
            state={isOpenLanguage ? "open" : "closed"}
            anchorRef={ref}
            onMouseLeave={() => setIsOpenLanguage(false)}
            onClose={() => setIsOpenLanguage(false)}
          >
            <MenuBox>
              {HEADER_LANGUAGES.map(({ title, short }) => {
                return (
                  <StyledMenuItem
                    key={short}
                    selected={language.toLocaleUpperCase() === short}
                    onClick={() => {
                      changeLanguage(short.toLocaleLowerCase());
                    }}
                  >
                    <ShortBox>{short}</ShortBox>
                    {title}
                  </StyledMenuItem>
                );
              })}
            </MenuBox>
          </ControlledMenu>
          <MobileBox>
            <Burger onClick={toggleDrawer}>
              <MdOutlineMenu size={28} />
            </Burger>
            <Drawer open={isOpen} onClose={toggleDrawer} direction="right">
              <DrawerBox>
                <MobileNavigation>
                  <Box
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      padding: "2px 10px",
                    }}
                  >
                    <Box>
                      <ThemeButton onClick={toggleDrawer}>
                        <MdArrowBack size={24} />
                      </ThemeButton>
                      <ThemeButton
                        style={{ marginRight: -6 }}
                        onClick={toggleTheme}
                      >
                        <ThemeIcon size={24} />
                      </ThemeButton>
                    </Box>
                    <MobileUserButton style={{ marginRight: -4 }} hovered>
                      <MdPersonOutline size={24} />
                    </MobileUserButton>
                  </Box>
                  <MobileNavLink href="https://t.me/s/vivy_page" target="_blank">
                    <MdDeveloperBoard size={24} style={{ marginRight: 8 }} />
                    {t("header:navbar.blog")}
                  </MobileNavLink>
                  <MobileNavLink href="https://community.vivysub.app">
                    <MdOutlineGroups size={24} style={{ marginRight: 8 }} />
                    {t("header:navbar.community")}
                  </MobileNavLink>
                  <MobileNavLink
                    href="https://boosty.to/vivy"
                    target="_blank"
                  >
                    <MdOutlineCoffee size={24} style={{ marginRight: 8 }} />
                    {t("header:navbar.support")}
                  </MobileNavLink>
                </MobileNavigation>
              </DrawerBox>
            </Drawer>
          </MobileBox>
          <DesktopBox>
            <ThemeButton onClick={toggleTheme}>
              <ThemeIcon size={24} />
            </ThemeButton>
            <AccountButton href="https://my.vivysub.app">
              <MdPersonOutline size={24} style={{ marginRight: 8 }} />
              {t("header:account")}
            </AccountButton>
          </DesktopBox>
        </Box>
      </HeaderContainer>
    </Wrapper>
  );
};

export default Header;
