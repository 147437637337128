import styled from "styled-components";

export const MenuBox = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 16px 0px;
  border-radius: 12px;
  color: ${(props) => props.theme.colors.text};
  min-width: 120px;
  margin-top: 20px;
  margin-left: -50px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;
