import styled from "styled-components";

export const AccountButton = styled.a`
  color: inherit;
  text-decoration: none;
  min-width: 120px;
  border-radius: 50px;
  background: rgb(33, 150, 243);
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 0%,
    rgba(33, 203, 243, 1) 70%,
    rgba(0, 212, 255, 1) 100%
  );
  transition: all 0.3s 0s ease;
  color: white;
  padding: 10px 16px;
  text-align: center;

  display: flex;
  align-items: center;
  &:hover {
    transform: scale(1.05);
  }
`;
