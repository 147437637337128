import styled from "styled-components";

export const Subtitle = styled.h2`
  font-size: 1.4rem;
  margin-bottom: 20px;
  max-width: 800px;
  text-align: center;
  line-height: 1.4;
  color: ${(props) => props.theme.colors.text};
  & > span {
    color: #2196f3;
  }

  @media screen and (max-width: 1080px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 550px) {
    font-size: 1rem;
    max-width: calc(100vw - 40px);
  }
`;
