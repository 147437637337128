import styled from "styled-components";

export const Screenshots = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;
