import { createContext, useContext } from "react";

export { darkTheme } from "./dark";
export { lightTheme } from "./light";

const defaultValue = localStorage.getItem("darkMode") === "true";

export const ThemeContext = createContext({
  dark: defaultValue,
  changeTheme: (dark) => {},
});

export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  return context;
};
