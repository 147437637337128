import styled, { css } from "styled-components";
import { MenuItem } from "@szhsin/react-menu";

const selectedStyledMenuItem = css`
  background-color: ${(props) => props.theme.colors.primary_light};
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${({ selected }) => selected && selectedStyledMenuItem}

  &:hover {
    background-color: ${(props) => props.theme.colors.primary_light};
  }
`;
