import styled from "styled-components";

export const Section = styled.div`
  min-height: 320px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.text};
  margin-top: 20px;
  display: flex;
  flex: 1;
  height: 100%;
  border-radius: 24px;
  max-width: 1280px;
  width: 100%;
  margin: 20px auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.1);
`;
