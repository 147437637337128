import {
  MdAutoAwesomeMotion,
  MdFilterAlt,
  MdOutlineLayers,
  MdOutlineMenuBook,
  MdOutlineSpellcheck,
  MdOutlineTexture,
} from "react-icons/md";
import { Container } from "../../Container";
import { Section } from "../Section";
import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }

  padding: 8px 0px;
`;

const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 24px;
`;

const FeatureIcon = styled.div`
  padding: 16px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    color: white;
    font-size: 2.6rem;
  }
`;

const FeatureInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;
  max-width: 280px;
`;

const FeatureTitle = styled.h3`
  font-weight: bold;
`;

const FeatureDescription = styled.p`
  margin-top: 6px;
`;

const translationFeatures = [
  [
    {
      Icon: MdOutlineTexture,
      title: "Hightlinghts",
      description: "Спецыяльныя тэгі можна падсвечваць у радках",
    },
    {
      Icon: MdFilterAlt,
      title: "Filters",
      description: "Вы можаце адфільтраваць радкі па стылях, акцёрах",
    },
    {
      Icon: MdAutoAwesomeMotion,
      title: "Group Lines",
      description:
        "Вы можаце згрупаваць радкі, якія паўтараюцца ў адзін, каб спрасціць працу",
    },
  ],
  [
    {
      Icon: MdOutlineSpellcheck,
      title: "Памылкі",
      description:
        "Праверка радкоў на памылкі з падказкамі (толькі беларуская)",
    },
    {
      Icon: MdOutlineMenuBook,
      title: "Слоўнікі",
      description: "Убудаваныя слоўнікі для вашай мовы (толькі беларуская)",
    },
    {
      Icon: MdOutlineLayers,
      title: "Гласарыі",
      description: "Пошук ужывання словаў (толькі беларуская)",
    },
  ],
];

export const TranslationFeatures = () => {
  return (
    <Section>
      <Container>
        {translationFeatures.map((subfeatures, index) => {
          return (
            <Box key={index}>
              {subfeatures.map(({ Icon, title, description }) => {
                return (
                  <Feature key={title}>
                    <FeatureIcon>
                      <Icon />
                    </FeatureIcon>
                    <FeatureInfo>
                      <FeatureTitle>{title}</FeatureTitle>
                      <FeatureDescription>{description}</FeatureDescription>
                    </FeatureInfo>
                  </Feature>
                );
              })}
            </Box>
          );
        })}
      </Container>
    </Section>
  );
};
