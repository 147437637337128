import styled, { css } from "styled-components";

export const selectedNavLink = css`
  background-color: ${(props) => props.theme.colors.primary_light};

  > svg {
    color: #21cbf3;
  }
`;

export const NavLink = styled.a`
  margin: 0px 8px;
  color: ${(props) => props.theme.colors.text};
  text-decoration: none;
  border-radius: 50px;
  height: 40px;
  padding: 2px 20px;
  display: flex;
  align-items: center;
  font-weight: 500;

  background-color: ${(props) => props.theme.colors.white};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary_light};
    > svg {
      color: #2196f3;
    }
  }

  ${({ selected }) => selected && selectedNavLink}
`;
