import styled from "styled-components";

export const MobileApplicationScreenshot = styled.img`
  width: 237px;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-left: 20px;

  @media screen and (max-width: 550px) {
    width: calc(50vw - 30px);
    margin: 0 10px;
    margin-bottom: 40px;
  }
`;
