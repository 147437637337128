export const uk = {
  header: {
    navbar: {
      blog: "Блог",
      community: "Спільнота",
      support: "Підтримати",
      download: "Завантажте",
    },
    account: "Рахунок",
  },
  footer: {
    terms: "Умови використання",
    privacy: "Політика конфіденційності",
    contact: "Контакти",
    faq: "FAQ",
  },
  home: {
    download: "Завантажте",
    docs: "Документація",
    description: `Сучасний редактор <span>.ass, .ssa</span> субтитрів під всі платформи,
    з хмарної синхронізацією і бібліотекою безкоштовних субтитрів!`,
  },
  download: {
    download_for: "Завантажити для",
    description: "Створюйте субтитри на <span>будь-якій</span> платформі.",
  },
};
