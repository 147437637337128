import React from "react";

import { Container } from "../components/Container";
import {
  FaApple,
  FaLinux,
  FaAndroid,
  FaWindows,
  FaAppStoreIos,
} from "react-icons/fa";
import { useThemeContext } from "../../core/theme";
import { useTranslation } from "react-i18next";
import { DesktopBox } from "../components/DesktopBox";
import { Description } from "../components/download/Description";
import { Box } from "../components/Box";

import { ApplicationScreenshot } from "../components/home/ApplicationScreenshot";
import { MobileBox } from "../components/MobileBox";
import { MobileApplicationScreenshot } from "../components/home/MobileApplicationScreenshot";
import { ButtonDownload } from "../components/download/ButtonDownload";

const DownloadPage = () => {
  const theme = useThemeContext();
  const { t } = useTranslation();
  return (
    <Container>
      <DesktopBox style={{ flexDirection: "column" }}>
        <Description
          dangerouslySetInnerHTML={{ __html: t("download:description") }}
        />

        <Box>
          <ButtonDownload href="https://cdn.vivysub.app/vivysub.exe">
            <FaWindows style={{ marginRight: 12 }} size={24} />
            {t("download:download_for")} Windows
          </ButtonDownload>
          <ButtonDownload href="https://cdn.vivysub.app/vivysub.dmg">
            <FaApple style={{ marginRight: 12, marginTop: -2 }} size={24} />
            {t("download:download_for")} MacOS
          </ButtonDownload>
          <ButtonDownload
            href={"https://cdn.vivysub.app/vivysub.AppImage"}
            target="_blank"
          >
            <FaLinux style={{ marginRight: 12 }} size={24} />
            {t("download:download_for")} Linux
          </ButtonDownload>
        </Box>

        <div>
          <ApplicationScreenshot
            src={
              theme.dark
                ? "/assets/images/be-editor-view-dark.jpeg"
                : "/assets/images/be-editor-view-light.jpeg"
            }
          />
        </div>
      </DesktopBox>
      <MobileBox style={{ flexDirection: "column" }}>
        <div>
          <MobileApplicationScreenshot
            src={
              !theme.dark
                ? "/assets/images/be-editor-video-mobile-light.png"
                : "/assets/images/be-editor-video-mobile-dark.png"
            }
          />
          <MobileApplicationScreenshot
            src={
              !theme.dark
                ? "/assets/images/be-edit-subtitle-mobile-light.png"
                : "/assets/images/be-edit-subtitle-mobile-dark.png"
            }
          />
        </div>
        <Box style={{ flexDirection: "column" }}>
          <ButtonDownload href="/">
            <FaAndroid style={{ marginRight: 12 }} size={24} />
            {t("download:download_for")} Android
          </ButtonDownload>
          <ButtonDownload href="/">
            <FaAppStoreIos style={{ marginRight: 12 }} size={24} />
            {t("download:download_for")} iOS
          </ButtonDownload>
        </Box>
      </MobileBox>
    </Container>
  );
};

export default DownloadPage;
