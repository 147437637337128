import "react-modern-drawer/dist/index.css";

import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./Application";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme, ThemeContext } from "./core/theme";

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(rootElement);
const defaultValue = localStorage.getItem("darkMode") !== undefined;

const Application = () => {
  const prefersDark = defaultValue
    ? localStorage.getItem("darkMode") === "true"
    : window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [darkMode, setDarkMode] = useState(prefersDark);
  const [theme, setTheme] = useState(darkMode ? darkTheme : lightTheme);

  const handleChangeTheme = useCallback(
    (dark) => {
      setDarkMode(dark);
      localStorage.setItem("darkMode", dark);
    },
    [setDarkMode]
  );

  useEffect(() => {
    if (darkMode) {
      setTheme(darkTheme);
    } else {
      setTheme(lightTheme);
    }
  }, [darkMode]);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <ThemeContext.Provider
          value={{ dark: darkMode, changeTheme: handleChangeTheme }}
        >
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ThemeContext.Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, <Application />);
} else {
  root.render(<Application />);
}
