import styled from "styled-components";

export const DesktopBox = styled.div`
  display: none;
  @media screen and (min-width: 1120px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
