import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterLink = styled(Link)`
  margin: 8px 16px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  padding: 6px 12px;
  border-radius: 12px;

  @media screen and (max-width: 680px) {
    padding: 16px 18px;
    margin: 4px 0px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary_light};
  }
`;
