import styled from "styled-components";

export const OutlinedButton = styled.a`
  color: inherit;
  text-decoration: none;
  border-radius: 28px;
  border: 2px solid rgb(33, 150, 243);
  color: rgb(33, 150, 243);
  box-shadow: 0px 10px 12px 4px rgb(33, 150, 243, 0.1);
  padding: 12px 24px;
  text-align: center;
  display: flex;
  align-items: center;
  transition: all 0.3s 0s ease;
  &:hover {
    transform: scale(1.05);
  }
  margin-bottom: 20px;
  margin-left: 20px;

  @media screen and (max-width: 580px) {
    width: 100%;

    margin-left: 0px;
    margin: 0px 24px;
    margin-bottom: 20px;
  }
`;
