export const COLORS = {
  PRIMARY: "#21cbf3",
  PRIMARY_DARK: "#2196f3",
  PRIMARY_LIGHT: "#21cbf324",
  BACKGROUND: "#ecf6ff",
  BACKGROUND_DARK: "#0c1923",
  WHITE: "white",
  WHITE_DARK: "#193449",
  DANGER: "#ff4a4a",
  BLACK: "#000",
};
