import styled from "styled-components";
import { Container } from "../../Container";
import { Section } from "../Section";

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }

  padding: 8px 0px;
`;

export const FeatureRequest = () => {
  return (
    <Section>
      <Container>Feature request</Container>
    </Section>
  );
};
