import { MdDownload, MdOutlineArticle } from "react-icons/md";
import { Container } from "../components/Container";
import { useThemeContext } from "../../core/theme";

import { useTranslation } from "react-i18next";
import { TabletBox } from "../components/TabletBox";
import { ApplicationScreenshot } from "../components/home/ApplicationScreenshot";
import { MobileBox } from "../components/MobileBox";
import { MobileApplicationScreenshot } from "../components/home/MobileApplicationScreenshot";
import { Button } from "../components/Button";
import { OutlinedButton } from "../components/OutlinedButton";
import { Subtitle } from "../components/home/Subtitle";
import { Screenshots } from "../components/home/Screenshots";
import { DesktopBox } from "../components/DesktopBox";
import { Features } from "../components/home/sections/Features";
import { TranslationFeatures } from "../components/home/sections/TranslationFeatures";
import styled from "styled-components";
import { ButtonDownload } from "../components/download/ButtonDownload";
import { FaAndroid, FaAppStoreIos } from "react-icons/fa";
import { FeatureRequest } from "../components/home/sections/FeatureRequest";

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 680px) {
    flex-direction: column;
    padding: 8px 16px;
    width: 100%;
  }
`;

const EmptySection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 0px;
`;

const EmpySectionTitle = styled.h3`
  font-weight: bold;
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.text};
  text-align: center;

  @media screen and (max-width: 680px) {
    font-size: 1.6rem;
  }
`;

const EmpySectionDescription = styled.p`
  font-size: 1.6rem;
  margin-top: 8px;
  max-width: 920px;
  text-align: center;
  color: ${(props) => props.theme.colors.text_secondary};

  @media screen and (max-width: 680px) {
    font-size: 1.2rem;
  }
`;

const EmptySectionInfo = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.text};
`;

const MobileAppSection = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <EmptySection style={{ marginBottom: -30 }}>
        <EmptySectionInfo>
          <EmpySectionTitle>Мабільная праграма</EmpySectionTitle>
          <EmpySectionDescription>
            VivySub даступны для Android і iOS!
          </EmpySectionDescription>
          <Box
            style={{
              marginTop: 24,
              marginBottom: 14,
            }}
          >
            <ButtonDownload href="/">
              <FaAndroid style={{ marginRight: 12 }} size={24} />
              {t("download:download_for")} Android
            </ButtonDownload>
            <ButtonDownload href="/">
              <FaAppStoreIos style={{ marginRight: 12 }} size={24} />
              {t("download:download_for")} iOS
            </ButtonDownload>
          </Box>
        </EmptySectionInfo>
      </EmptySection>
    </Container>
  );
};

function HomePage() {
  const theme = useThemeContext();
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <TabletBox>
          <ApplicationScreenshot
            src={
              theme.dark
                ? "./assets/images/be-editor-view-dark.jpeg"
                : "./assets/images/be-editor-view-light.jpeg"
            }
          />
        </TabletBox>
        <MobileBox>
          <MobileApplicationScreenshot
            src={
              !theme.dark
                ? "./assets/images/be-editor-video-mobile-light.png"
                : "./assets/images/be-editor-video-mobile-dark.png"
            }
          />
          <MobileApplicationScreenshot
            src={
              !theme.dark
                ? "./assets/images/be-edit-subtitle-mobile-light.png"
                : "./assets/images/be-edit-subtitle-mobile-dark.png"
            }
          />
        </MobileBox>
        <Box>
          <Button to="/download">
            <MdDownload size={24} style={{ marginRight: 8 }} />{" "}
            <p>{t("home:download")}</p>
          </Button>
          <OutlinedButton href="https://docs.vivysub.app" target="_blank">
            <MdOutlineArticle size={24} style={{ marginRight: 8 }} />
            <p>{t("home:docs")}</p>
          </OutlinedButton>
        </Box>

        <Subtitle dangerouslySetInnerHTML={{ __html: t("home:description") }} />
        <Screenshots>
          <ApplicationScreenshot
            src={
              theme.dark
                ? "./assets/images/be-editor-view-dark.jpeg"
                : "./assets/images/be-editor-view-light.jpeg"
            }
          />
          <DesktopBox>
            <MobileApplicationScreenshot
              src={
                !theme.dark
                  ? "./assets/images/be-editor-mobile-light.png"
                  : "./assets/images/be-editor-mobile-dark.png"
              }
            />
          </DesktopBox>
        </Screenshots>
      </Container>
      <Features />
      <Container>
        <EmptySection>
          <EmptySectionInfo>
            <EmpySectionTitle>Ствараць субцітры лёгка</EmpySectionTitle>
            <EmpySectionDescription>
              Мэта праграмы спрасціць стварэнне субцітраў, робячы працэс
              максімальна камфортным і лёгкім!
            </EmpySectionDescription>
          </EmptySectionInfo>
        </EmptySection>
      </Container>
      <TranslationFeatures />
      <MobileAppSection />
      <FeatureRequest />
    </>
  );
}

export default HomePage;
