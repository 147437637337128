import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { be } from "./be";
import { en } from "./en";
import { ru } from "./ru";
import { uk } from "./uk";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en,
      be,
      ru,
      uk,
    },
    fallbackLng: "be",
    supportedLngs: ["be", "ru", "en", "uk"],
    interpolation: {
      escapeValue: false,
    },
  });
