import {
  MdCloud,
  MdFolderShared,
  MdGroups,
  MdOndemandVideo,
  MdOutlineFileDownload,
  MdOutlineFileUpload,
} from "react-icons/md";

import styled from "styled-components";
import { Container } from "../../Container";
import { Section } from "../Section";

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 680px) {
    flex-direction: column;
  }

  padding: 8px 0px;
`;

const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 24px;
`;

const FeatureIcon = styled.div`
  padding: 16px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    color: white;
    font-size: 2.6rem;
  }
`;

const FeatureInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;
  max-width: 280px;
`;

const FeatureTitle = styled.h3`
  font-weight: bold;
`;

const FeatureDescription = styled.p`
  margin-top: 6px;
`;

const features = [
  [
    {
      Icon: MdCloud,
      title: "Cloud Sync",
      description:
        "Воблачнае сінхразаванне дае Вам магчымасць працаваць з любой прылады",
    },
    {
      Icon: MdGroups,
      title: "Community",
      description:
        "Платформа на якую Вы можаце загрузіць свае субцітры або спампаваць ужо гатовыя",
    },
    {
      Icon: MdFolderShared,
      title: "Team",
      description:
        "Працуючы у камандзе Вы можаце размеркаваць ролі для яе ўдзельнікаў",
    },
  ],
  [
    {
      Icon: MdOutlineFileUpload,
      title: "Share",
      description:
        "Абагульвайце субцітры з магчымасцю спампоўвання і перадпрагляда ў браўзеры",
    },
    {
      Icon: MdOndemandVideo,
      title: "Video",
      description: "Падтрымка сучасных відэа фарматаў для перадпрагляду",
    },
    {
      Icon: MdOutlineFileDownload,
      title: "Render",
      description: "Больш не патрэбны асобныя праграмы для рэндара субцітраў",
    },
  ],
];

export const Features = () => {
  return (
    <Section>
      <Container>
        {features.map((subfeatures, index) => {
          return (
            <Box key={index}>
              {subfeatures.map(({ Icon, title, description }) => {
                return (
                  <Feature key={title}>
                    <FeatureIcon>
                      <Icon />
                    </FeatureIcon>
                    <FeatureInfo>
                      <FeatureTitle>{title}</FeatureTitle>
                      <FeatureDescription>{description}</FeatureDescription>
                    </FeatureInfo>
                  </Feature>
                );
              })}
            </Box>
          );
        })}
      </Container>
    </Section>
  );
};
