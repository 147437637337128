import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  height: 60px;
  margin-bottom: 40px;
  box-shadow: 0px 11px 36px 7px rgba(0, 0, 0, 0.02);
  position: sticky;
  top: 0;
  z-index: 1000;

  @media screen and (max-width: 1260px) {
    border-radius: 50px;
    box-shadow: -1px 4px 15px 3px rgb(33 150 243 / 18%);
    top: 16px;
    left: 8px;
    right: 8px;
    margin-left: 16px;
    margin-right: 16px;
    width: auto;
  }

  @media screen and (min-width: 1260px) {
    border-radius: 50px;
    box-shadow: -1px 4px 15px 3px rgb(33 150 243 / 18%);
    top: 16px;
    left: unset;
    right: unset;
    margin-left: unset;
    margin-right: unset;
    max-width: 1240px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
`;
