import styled from "styled-components";
import { LanguageButton } from "./LanguageButton";

export const ThemeButton = styled(LanguageButton)`
  margin: 0px;
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 16px;
`;
