import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const style = css`
  color: inherit;
  text-decoration: none;
  border-radius: 28px;
  background: rgb(33, 150, 243);
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 0%,
    rgba(33, 203, 243, 1) 70%,
    rgba(0, 212, 255, 1) 100%
  );
  box-shadow: 0px 10px 12px 4px rgb(33, 150, 243, 0.2);
  color: white;
  padding: 12px 24px;
  text-align: center;
  display: flex;
  align-items: center;
  transition: all 0.3s 0s ease;
  &:hover {
    transform: scale(1.05);
  }
  margin-bottom: 20px;
  margin-left: 20px;

  @media screen and (max-width: 580px) {
    width: 100%;

    margin-left: 0px;
    margin: 0px 24px;
    margin-bottom: 20px;
  }
`;

const GeneralLink = styled.a`
  ${style}
`;

const RouterLink = styled(Link)`
  ${style}
`;

export const Button = (props) => {
  const Component = props.href ? GeneralLink : RouterLink;

  return <Component {...props} />;
};
